// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aerzte-js": () => import("./../../../src/pages/aerzte.js" /* webpackChunkName: "component---src-pages-aerzte-js" */),
  "component---src-pages-datenschutzbestimmungen-js": () => import("./../../../src/pages/datenschutzbestimmungen.js" /* webpackChunkName: "component---src-pages-datenschutzbestimmungen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-schulungen-js": () => import("./../../../src/pages/schulungen.js" /* webpackChunkName: "component---src-pages-schulungen-js" */)
}

